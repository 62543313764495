/* eslint-disable eqeqeq */
import React from 'react';
import { getRequestParams, getInstaLoginCookie, getInstaUseridCookie } from '../custom_views/insta_custom_views/utils_android/CookieHelper';
import { openUrl } from '../custom_views/insta_custom_views/utils_android/AndroidMethods';
import { getHttpParams, getRequestUrl } from '../custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import { getSmoozieMultiplier } from '../custom_views/insta_custom_views/utils_android/UtilsAndroid';

const CustomTaskItem = (props) => {
    const openTask = () => {
        if (props.task._id == "-9") {
            openUrl(props.task.url)
            getMoneyFromVideo2()
        } else if (props.task._id == "0") {
            const hasInstaLogin = getInstaLoginCookie() != null && getInstaLoginCookie() != undefined && getInstaLoginCookie() != ""
            const hasInstaUserId = getInstaUseridCookie() != null && getInstaUseridCookie() != undefined && getInstaUseridCookie() != ""
            if (hasInstaLogin && hasInstaUserId) {
                props.openViewBack("earn_insta", "tasks")
            } else {
                props.openViewBack("auth_insta", "tasks")
            }
        } else if (props.task._id == "1") {
            props.openViewBack("tiktok_task", "tasks")
        } else if (props.task._id == "2") {
            props.onTaskClicked(props.task, "login_task", "tasks")
        } else {
            if (props.task.control_text) {
                startPerfomYoutube()
            } else {
                var task = props.task
                task.task_data = { 'proof_order_id': '-1', 'orderState': '3', 'orderAnswer': '' }
                props.onTaskClicked(task, 'custom_task', 'tasks')
            }
        }
    }

    const getMoneyFromVideo2 = () => {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getMoneyFromVideo2", getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.response > 0) {
                    props.updateMoney(0.03)
                    if (props.count_money_r >= 0.03) {
                        props.updateOrders()
                    }
                }
            })
    }

    const startPerfomYoutube = () => {
        console.log("startPerfomYoutube")
        if (props.orderState != undefined) {
            var task = props.task
            task.task_data = {
                'proof_order_id': (props.proof_order_id ? props.proof_order_id : "123"),
                'orderState': (props.orderState != undefined ? props.orderState : 3),
                'orderAnswer': (props.proof_text ? props.proof_text : ""),
                'correction': props.correction
            }
            props.onTaskClicked(task, 'custom_task', (props.isActive ? 'active' : 'finished'))
            return
        }

        var task = props.task
        task.task_data = {
            'orderState': (props.orderState != undefined ? props.orderState : 3),
            'orderAnswer': (props.proof_text ? props.proof_text : "")
        }
        props.onTaskClicked(task, 'custom_task', 'tasks')
    }

    return (
        <div>
            {(props.item && props.item.dateTitle) && <p style={{ marginLeft: '16px' }} className="task_name">{props.item.dateTitle}</p>}
            <div className="task_item_container hover" onClick={openTask}>
                <div className="flex task_item_full_width">
                    <img className="task_item_icon custom_icons_task_shadow" src={props.task.icon} alt="" />
                    <div className="block justify_content task_item_full_width">
                        <p className="task_item_title task_name_overflow">{props.task.name}</p>
                        <p className="task_item_type description_full custom_desc"
                            dangerouslySetInnerHTML={{ __html: props.description_state ? props.description_state : "Выполните задание и получите деньги" }}>
                        </p>
                        {/* {props.orderState == undefined && 
                                <p className={(props.task._id == "0" || props.task._id == "1") ? "not_display" : "task_item_price"}>
                                    +{props.task.count_earning_employer_r}₽
                                </p>
                            }  */}
                    </div>
                    
                </div>
                <div className="flex">
                    {/* <p className={(props.task._id == "0" || props.task._id == "1") ? "not_display" : "task_item_money_c"}>
                        +{props.task.count_earning_employer_r}₽
                    </p> */}
                    {!props.isUpmob ? 
                        <div className={(props.task._id == "0" || props.task._id == "1") ? "not_display" : "task_item_money_c flex"}>+{props.task.count_earning_employer_r * getSmoozieMultiplier()}<span>🍊</span></div>
                    :
                        <p className={(props.task._id == "0" || props.task._id == "1") ? "not_display" : "task_item_money_c"}>+{props.task.count_earning_employer_r}₽</p>
                    }
                    {/* <div className="notification_text"><p className="notif_text_m">1</p></div> */}
                    {props.orderState == 2 && <div className="notification_text"><p className="notif_text_m">1</p></div>}
                </div>
            </div>
        </div>
    );
}

export default CustomTaskItem;