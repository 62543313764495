/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import auth_img from '../icons/auth/auth_img.png'
import { Pages } from './custom_views/insta_custom_views/utils_android/UtilsAndroid';
import { getHttpParams} from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import { getRequestParams, saveBundle, saveRegistrCookie } from './custom_views/insta_custom_views/utils_android/CookieHelper';
const Auth = (props) => {
    const [promocode, setPromocode] = useState("")
    useEffect(() => {
       
    }, [])
    const responseMessage = (response) => {
        console.log(response);
        console.log("response = ", response.credential)
        registerUser(response.credential)
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    const registerUser = (token) => {
        var params = getRequestParams()
        var device_id = "asdasd"
        var token_google = token
        var uniq_user_id = ""
        var api_key = "upmob"
        var bundle = "web_bundle"
        if (bundle != "" && bundle != undefined && bundle != null){
            saveBundle(bundle)
        }
        params.device_id = device_id
        params.token_google = token_google
        params.easy_money_user_id = uniq_user_id
        params.api_key = api_key
        params.bundle = bundle
        if (promocode){
            params.promo_id = promocode
        }
        if (api_key == "lexus"){
            params.promo_id = "945113"
        } else if (api_key == "QWT"){
            params.promo_id = "2554224"
        } else if (api_key == "QWT2"){
            params.promo_id = "2719566"
        }
        fetch("https://app-coins.ru/api/registerEmployer", getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                console.log("registerEmployer = ", responseData)
                if (responseData.status == "OK" && responseData.response > 0) {
                    saveRegistrCookie(responseData, device_id, uniq_user_id, api_key);
                    window.location.href = Pages.tasks
                }
            });
    }
    return (
        <div className="auth_container">
           <div>
                <div className='auth_title'>У тебя есть промокод?</div>
                <input className='auth_promocode' placeholder='Промокод' onChange={(e) => setPromocode(e.target.value)} value={promocode}/>
                <div className='auth_login_google'><img alt='auth img' className='auth_img' src={auth_img}/></div>
                <div className='auth_login_google'><GoogleLogin onSuccess={responseMessage} onError={errorMessage} /></div>
            </div>
        </div>
    );
};

export default Auth;