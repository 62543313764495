/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import ActiveTaskItem from './list_items/ActiveTaskItem';
import ufoIcon from './custom_views/insta_custom_views/insta_icons_tasks/ufo_android.png';
import CustomTaskItem from './list_items/CustomTaskItem';
import { Pages, setDateTitles } from './custom_views/insta_custom_views/utils_android/UtilsAndroid';
import { TasksApi } from '../components_android/api/TasksApi';

const FinishedTasksAndroid = (props) => {
    const [done, setDone] = useState(false);
    const [allTasks, setAllTasks] = useState([]);

    useEffect(() => {
        getMyActiveYoutube();
    }, []);

    const getMyActiveYoutube = () => {

        TasksApi.getMyActiveYoutube((responseData) => {
            if (responseData.response > 0 && responseData.proof_orders_youtube && responseData.proof_orders_youtube.length > 0) {
                var activeTasks = responseData.proof_orders_youtube.filter(task =>
                    (task.state == 1 || task.state == -1) && task.order != null
                );
                
                activeTasks.forEach(element => {
                    element.is_universal = true
                    
                });
                setTasks(activeTasks)
            } else {
                setTasks([])
            }
            setDone(true)
        }, () => {
            setTasks([])
            setDone(true)
        })

    }
    const setTasks = (activeTasks) => {
        var tasks = getFinishedTasks();
        tasks.push(...activeTasks)
        tasks.sort(function (a, b) {
            return new Date(b.modified) - new Date(a.modified);
        })
        tasks = setDateTitles(tasks)
        console.log("tasks = ", tasks)
        setAllTasks(tasks);
    }
    const getFinishedTasks = () => {
        if (props.info?.progress_orders) {
            return props.info.progress_orders
                .filter(element => {
                    element.category = "Завершено";
                    if (!element.progressArray.includes(0)) {
                        if (element.progressArraySDK?.length > 0) {
                            return !element.progressArraySDK.includes(0);
                        }
                        return true;
                    }
                    return false;
                })
                .reverse();
        }
        return [];
    }

    if (!done) return null;



    if (allTasks.length > 0 || allTasks.length > 0) {
        return (
            <div className='tasks_list_container'>
                <p style={{ marginLeft: '16px' }} className="task_name">Завершенные задания:</p>
                {allTasks.map((item, index) => (
                    <div key={index}>
                        {item.is_universal ?
                            <CustomTaskItem
                                key={index}
                                onTaskClicked={props.onTaskClicked}
                                correction={item.correction}
                                isActive={false}
                                proof_order_id={item._id}
                                orderState={item.state}
                                proof_text={item.proof_text}
                                task={item.order}
                                isUpmob={props.isUpmob}
                                item={item}
                                index={index}
                                description_state={item.state == 1 ? "<p style='color : #2DB789'>Выполнено</p>" :
                                    (item.state == 2 ? "<p style='color : #E17373'>Нужно доработать</p>" :
                                        (item.state == -1 ? "<p style='color : #E17373'>Отклонено</p>" :
                                            (item.state == 0 ? "<p>На проверке</p>" :
                                                (item.state == 3 ? "<p>Выполняется</p>" : undefined))))}
                            />
                            :
                            <ActiveTaskItem
                                key={index}
                                backView={Pages.profile}
                                onTaskClicked={props.onTaskClicked}
                                isActive={false}
                                task={item}
                                isUpmob={props.isUpmob}
                            />
                        }
                    </div>
                ))}

            </div>
        );
    }

    return (
        <div className="empty_tasks_container">
            <div className="display_block">
                <img style={{ marginTop: '24px' }} className="ufo_icon" src={ufoIcon} alt="" />
                <p className="empty_tasks_text_new">На данный момент<br />завершенных заданий нет</p>
            </div>
        </div>
    );
}

export default FinishedTasksAndroid;